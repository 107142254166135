// routes
import { PATH_PAGE } from '../../../routes/paths'; // PATH_AUTH, PATH_DOCS, PATH_DASHBOARD
// config
// import { PATH_AFTER_LOGIN } from '../../../config-global';
// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const navConfig = [
  {
    title: 'Home',
    icon: <Iconify icon="eva:home-fill" />,
    path: '/',
  },
  {
    title: 'Security',
    icon: <Iconify icon="eva:home-fill" />,
    path: PATH_PAGE.contact,
  },
  {
    title: 'Pricing',
    icon: <Iconify icon="eva:home-fill" />,
    path: PATH_PAGE.pricing,
  },
  {
    title: 'About us',
    icon: <Iconify icon="eva:home-fill" />,
    path: PATH_PAGE.about,
  },
  {
    title: 'FAQ',
    icon: <Iconify icon="eva:home-fill" />,
    path: PATH_PAGE.faqs,
  },
];

export default navConfig;
