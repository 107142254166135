// routes
import { PATH_DASHBOARD, PATH_PAGE } from '../../../routes/paths';
// components
// import Label from '../../../components/label';
// import Iconify from '../../../components/iconify';
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

// delete unused icons
const ICONS = {
  blog: icon('ic_blog'),
  cart: icon('ic_cart'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      { title: 'Portfolio', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
    ],
  },

    // Analysis
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'Analysis',
  //   items: [
  //     // { title: 'Stock', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce },
  //     { title: 'USDT Derivatives', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
  //   ],
  // },

    // History
  // ----------------------------------------------------------------------
  {
    subheader: 'History',
    items: [
      { title: 'Journal', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'management',
  //   items: [
      // // USER
      // {
      //   title: 'user',
      //   path: PATH_DASHBOARD.user.root,
      //   icon: ICONS.user,
      //   children: [
      //     { title: 'profile', path: PATH_DASHBOARD.user.profile },
      //     { title: 'create', path: PATH_DASHBOARD.user.new },
      //     { title: 'edit', path: PATH_DASHBOARD.user.demoEdit },
      //     { title: 'account', path: PATH_DASHBOARD.user.account },
      //   ],
      // },

      // // E-COMMERCE
      // {
      //   title: 'ecommerce',
      //   path: PATH_DASHBOARD.eCommerce.root,
      //   icon: ICONS.cart,
      //   children: [
      //     { title: 'product', path: PATH_DASHBOARD.eCommerce.demoView },
      //     { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
      //   ],
      // },

      // INVOICE
      // {
      //   title: 'invoice',
      //   path: PATH_DASHBOARD.invoice.root,
      //   icon: ICONS.invoice,
      //   children: [
      //     { title: 'list', path: PATH_DASHBOARD.invoice.list },
      //     // { title: 'details', path: PATH_DASHBOARD.invoice.demoView },
      //     // { title: 'create', path: PATH_DASHBOARD.invoice.new },
      //     // { title: 'edit', path: PATH_DASHBOARD.invoice.demoEdit },
      //   ],
      // },

      // // BLOG
      // {
      //   title: 'blog',
      //   path: PATH_DASHBOARD.blog.root,
      //   icon: ICONS.blog,
      //   children: [
      //     { title: 'create', path: PATH_DASHBOARD.blog.new },
      //   ],
      // },
      // {
      //   title: 'File manager',
      //   path: PATH_DASHBOARD.fileManager,
      //   icon: ICONS.folder,
      // },
  //   ],
  // },

    // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'need help?',
    items: [
      { title: 'FAQ', path: PATH_PAGE.faqs, icon: ICONS.booking },
      // { title: 'Contact support', path: PATH_DASHBOARD.general.file, icon: ICONS.file },
    ],
  },

  // Give us your opinion!
  // ----------------------------------------------------------------------
  {
    subheader: 'Give us your opinion',
    items: [
      { title: 'Feedback', path: "https://discord.com/channels/1049755778883526766/1049792161438248970", icon: ICONS.file },
    ],
  },

  // // DEMO MENU STATES
  // {
  //   subheader: 'Other cases',
  //   items: [
  //     {
  //       title: 'item_caption',
  //       path: '#caption',
  //       icon: ICONS.menuItem,
  //       caption:
  //         'Quisque malesuada placerat nisl. In hac habitasse platea dictumst. Cras id dui. Pellentesque commodo eros a enim. Morbi mollis tellus ac sapien.',
  //     },
  //     {
  //       title: 'item_external_link',
  //       path: 'https://www.google.com/',
  //       icon: ICONS.external,
  //     },
  //     {
  //       title: 'blank',
  //       path: PATH_DASHBOARD.blank,
  //       icon: ICONS.blank,
  //     },
  //   ],
  // },
];

export default navConfig;
